@import './css/main';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ws-default{
  background-color:$primary-color!important;
  color: white!important;
  padding: .1rem .1rem;
  border: none;
  border-radius: .7rem;
  cursor: pointer;
 // transition: background-color 0.3s ease;
  &:hover {
    background-color: $secondary-color!important;; 
  }
  &.disabled{
    background-color:$gray-color!important;
  }
}
.WSError{
  border-color: $error!important;
}
.reduced-height {
  color:green!important;
  min-height: 20px!important; /* Adjust as needed */
  font-size: x-small!important;
}

.reduced-height .MuiInputBase-root {
  min-height: 20px!important; /* Adjust as needed */
  line-height: 20px!important; /* Adjust as needed */
}
.ws-grid{
  line-height: 5px!important;
}
.popover-content {
  padding-left: 16px;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 1px solid #ddd; /* Adds a light border around the content */
  border-radius: 14px; /* Rounds the corners of the border */
  background-color: #fff; /* Sets the background color to white */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  max-width: 100%; /* Sets a maximum width for the popover */
  width: 100%; /* Ensures the popover takes up the full width of its container */
 
}

.popover-content form {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  gap: 16px; /* Adds space between children */
}
.popover-content .text-width{
  width: 200px;
  height: 10px;
}

.popover-main{
  .MuiPaper-root{
    width: 50%!important;
    border-radius: 14px; 
  }
}
.popover-content .MuiGrid-item {
  width: 100%; /* Ensure grid items take up full width */
  
}
.popover-content .WSTextField {
  width: 200px!important; 
  .MuiInputBase-root {
    height: 40px; // Adjust this value as needed
    font-size: 0.875rem; // Adjust font size if needed

    .MuiInputBase-input {
      padding: 4px 8px; // Adjust padding as needed
      height: 30px; // Adjust this value to match the root height if needed
      box-sizing: border-box;

      &::placeholder {
        font-size: 0.875rem; // Adjust the placeholder text size
        color: #999; // Adjust the placeholder text color if needed
      }
    }

    .MuiOutlinedInput-notchedOutline {
      top: 0; // Adjust this value as needed to align the outline with the input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size if needed
    transform: translate(14px, 10px) scale(1); // Adjust to move the label as needed
    transition: transform 0.3s ease, font-size 0.3s ease;

    &.Mui-focused,
    &.MuiFormLabel-filled {
      transform: translate(14px, -6px) scale(0.75); // Adjust this to control the floating label position
      font-size: 0.75rem; // Adjust font size when label is floating
    }
  }

  .MuiFormHelperText-root {
    font-size: 0.75rem; // Adjust helper text size if needed
  }
}



.popover-content .MuiButton-root {
  align-self: flex-end; /* Align the submit button to the end of the form */
}

.ws-cancel {
  background-color: gray!important;
  color: white!important;

  &:hover {
    background-color: #696969!important;
  }
}
.ws-dialog{
  // padding-left: 40px!important;;
  background-color: #F5F5F5!important;;
  &-title{
    padding-left: 1.5rem!important;
    padding-top: 1rem!important;
  }
}
/* ThreeDotMenu.css */
.icon-button-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon-button-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div-shape {
  width: 200px;
  height: 200px;
  margin-left: 10px;
  position: relative;
  background-color:green/* Background color for the div */
}
.div-shape:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 90px; /* Adjust as needed */
  width: 20px;  /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}
.ws-grid-table {
  border: 1px solid #f4f4f4;
  margin-top: 20px!important;
  //padding-bottom: 20px;
  .child-cell{
    border: 1px solid #f4f4f4;
    padding-bottom: 15px;
    padding-right: 15px;
    
  }
  .header {
    font-weight: bold;
  }
}
.MuiInputAdornment-root.suffixAdornment {
  display: flex;
  align-items: center;
  border-left: 1px solid gray;
  padding-left: 8px;
  margin-left: 8px;
  color: blue!important;
  font-size: 0.875rem;
}
.MuiInputAdornment-root.suffixAdornmentSuccess {
  display: flex;
  align-items: center;
  border-left: 1px solid gray;
  padding-left: 8px;
  margin-left: 8px;
  color: green!important;
  font-size: 0.875rem;
}
.custom-dialog .MuiPaper-root-MuiDialog-paper{
  max-width: 1200px!important;
  min-height: 200px;
  /* width: 500px;
  height: 400px; */
}
.ws-data-grid{
  &-header{
    color: gray!important;
    font-weight:bold !important;
    font-size: 0.75rem!important;
  }
  &-title .MuiTypography-root{
    padding-top: 20!important;;
    padding-left: 50!important;;
    color: #36454F;
    font-weight: bold;
  }
}
.ws-date-picker-width{
  border:1px solid red
}
.master-input-main-paper.MuiPaper-root {
  padding: 25px;
  margin: 25px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.header-text.MuiTypography-root{
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 5px;
}
.modal-input.MuiGrid-root{
   padding: "20px";  
   position: "relative";
    margin: "0";
   margin-top: 3rem
   }
.ws-hidden{
  display: none!important;
}

.ws-reject {
  background-color: red!important;
  color: white!important;

  &:hover {
    background-color: #EE4B2B!important;
  }
}
.ws-success{
  background-color:$success-color!important;
  color: white!important;
  padding: .1rem .1rem;
  border: none;
  border-radius: .7rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #01686f!important;; 
  }
}

.ws-permission-denied{
  &.text.MuiTypography-root{
    font-size: 1rem!important;

}
}