.notification-popover {
    width: 300px; /* Adjust the width as needed */
    max-height: 400px; /* Optional: Limit the height for better scrolling */
    overflow:auto; /* Enable scrolling if content overflows */
    padding: 10px; /* Padding inside the popover */
    background-color: #fff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
  
  .notification-popover h3 {
    margin-bottom: 10px; /* Space below the title */
    font-size: 18px; /* Font size for title */
    color: #333; /* Title text color */
  }
  
  .dropdown-item {
    display: flex; /* Flexbox layout for notifications */
    padding: 10px; /* Padding around each notification item */
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Text color */
    transition: background-color 0.2s; /* Smooth background transition */
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0; /* Background color on hover */
  }
  
  .media {
    display: flex; /* Flexbox for media layout */
  }
  
  .img-size-50 {
    width: 50px; /* Set image size */
    height: 50px; /* Set image size */
    border-radius: 50%; /* Circle shape */
  }
  
  .media-body {
    margin-left: 10px; /* Space between image and text */
    flex-grow: 1; /* Allow text to take available space */
  }
  
  .dropdown-item-title {
    margin: 0; /* Remove default margin */
    font-weight: bold; /* Bold title */
  }
  
  .text-sm {
    font-size: 14px; /* Smaller text size for descriptions */
  }
  
  .text-muted {
    color: #888; /* Muted text color */
  }
  
  .dropdown-divider {
    height: 1px; /* Height of divider */
    background-color: #e0e0e0; /* Divider color */
    margin: 5px 0; /* Space above and below the divider */
  }
  
  .dropdown-footer {
    display: block; /* Block display for footer link */
    text-align: center; /* Center align the footer */
    color: #007bff; /* Link color */
    margin-top: 10px; /* Space above the footer */
  }
  
  .dropdown-footer:hover {
    text-decoration: underline; /* Underline on hover */
  }
  